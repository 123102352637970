.toolbox-year {
    float: left;
    max-width: 100%;
    margin-left: 18px;

    span {
        &.icon {
            top: 2px !important;
        }
    }

    .is-normal {
        flex-basis: auto;
        padding-top: 10px;

        .label {
            font-weight: normal;
            font-size: 12px;
        }
    }
    
    .is-rounded {
        font-family: "PoppinsMedium"!important;
    }
}
