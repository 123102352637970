.wipSelection {
  margin-bottom: 25px;
  header {
    &.card-header {
      .card-header-title {
        padding: 0 5px 10px;
        font-size: 20px;
      }
    }
  }
}
