.viewerCycleItem {
  height: 110px;
  &.columns {
    .column {
      border-right: solid #e8e8e8;
      border-width: 0.1px;
      .is-size-2-top {
        font-size: 16px;
      }
      .is-size-2 {
        font-size: 10px;
        padding: 2px;
        padding-left: 10px;
      }
      &.p-3 {
        padding: 9px !important;
        height: 80px;
        margin: 4px;
        .relative {
          position: relative;
          .right-item {
            .panels {
              font-size: 10px;
              display: block;
              position: absolute;
              top: -6px;
              right: 0px;
            }
            .qty {
              margin-left: -20px;
            }
            .inline-box {
              display: -webkit-inline-box;
            }
          }
        }
        .absolute {
          position: absolute;
          right: 0;
          bottom: 20px;
          text-align: center;
        }
        .mt15 {
          width: 100%;
          margin-left: 22px;
          margin-bottom: 5px;
          float: left;
          .is-size-2:first-child {
            font-size: 12px;
          }
        }
        .lh0 {
          line-height: 0;
        }
      }
      .has-text-left {
        color: #11141a;
        font-size: 8px;
      }
      &:last-child {
        border-right: none;
      }
      .numberbullet {
        border: 1px solid;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: -webkit-center;
        border-radius: 100%;
        background-color: #3f8cff;
        color: #fff;
        font-size: 10px;
        line-height: 1.8em;
        vertical-align: middle;
        margin-right: 5px;
        margin-left: -5px;
        &.blue {
          background-color: #3f8cff;
        }
        &.black {
          background-color: #000000;
        }
      }
      .numbercycles {
        font-size: 16px;
        font-family: 'PoppinsMedium';
        color: #11141a;
      }
    }
  }
}
