.isActive {
  background-color: #3f8cff !important;
  h3,
  h4 {
    color: #ffffff !important;
  }
}
.isBordered {
  border: 2px solid #ffa500 !important;
  color: #ffa500;
  h4 {
    color: #ffa500;
  }
}
