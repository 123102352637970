div.loginPage {
    .columns {
        &.is-gapless {
            .column {
                &.customBgForm {
                    background-color: #ffffff;
                    padding: 60px !important;

                    .has-text-centered {
                        text-align: center;

                        h4 {
                            color: #000000;
                        }
                    }

                    form {
                        .field {
                            margin-bottom: 30px;

                            label {
                                font-size: 12px;
                                color: #000000;
                                margin-bottom: 10px;
                                font-weight: normal;
                            }

                            .control {
                                border-radius: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .bgImg {
        background-image: url('../../../assets/images/download.jpeg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .customMt {

        img.logo {
            width: 160px;
            margin-bottom: 20px;
        }

        h3 {
            text-align: center;
            font-size: 22px;
            color: #000000;
            margin-bottom: 10px;
        }

        h4 {
            text-align: center;
            font-size: 16px;
            color: #d8cfcf;
            margin-bottom: 10px;
        }
    }

    .powerby {
        margin-top: 200px;
        font-size: 14px;
    }
}
