.columnsInfo {
  margin-bottom: 25px !important;
  .column {
    .card {
      min-height: 110px;
      border-radius: 10px;
      box-shadow: 0px 5px 30px #20202312 !important;
      &.card-custom {
        box-shadow: 0px 5px 30px #20202312;
      }
      .card-content {
        margin-top: 5px;
        .subtitle {
          color: #11141a;
          font-size: 14px;
          .icon2 {
            background-color: #47d5cb;
            vertical-align: middle;
            margin-right: 8px;
            width: 20px;
            padding: 5px;
            border-radius: 100%;
          }
          .icon1 {
            background-color: #3f8cff;
            vertical-align: middle;
            margin-right: 8px;
            width: 20px;
            padding: 5px;
            border-radius: 100%;
          }
          .icon3 {
            background-color: #ffa2c0;
            vertical-align: middle;
            margin-right: 8px;
            width: 20px;
            padding: 5px;
            border-radius: 100%;
          }
        }
        .title {
          color: #11141a;
          font-size: 18px;
          padding-top: 15px;
        }
      }
    }
  }
}
