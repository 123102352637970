.cycleItemStatus {
  .media-left {
    margin-top: 6px;
    figure {
      width: 44px;
      height: 42px;
      padding: 10px;
      background: #3f8cff;
      border-radius: 10px;
    }
  }
  .media-content {
    .title {
      color: #11141a;
      font-size: 14px;
      span {
        .edit-icon {
          width: 15px;
          cursor: pointer;
          margin-left: 10px;
          vertical-align: middle;
        }
        .save-icon {
          width: 23px;
          cursor: pointer;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
    .subtitle {
      color: #a6a9b7;
      font-size: 12px;
    }
  }
}
