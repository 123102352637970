.cycleItem {
  background-color: #fff;
  border: 0.5px solid #e8e8e8;
  border-radius: 6px;
  height: 126px;
  &.columns {
    .column {
      border-right: solid #e8e8e8;
      border-width: 0.1px;
      &.p-3 {
        padding: 15px !important;
        .mt15 {
          margin-top: 15px;
        }
        .lh0 {
          line-height: 0;
        }
      }
      .has-text-left {
        color: #11141a;
        font-size: 10px;
        // &.is-size-3 {
        //   font-size: 8px;
        // }
      }
      &:last-child {
        border-right: none;
      }
      .numberbullet {
        border: 1px solid;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: -webkit-center;
        border-radius: 100%;
        background-color: #3f8cff;
        color: #fff;
        font-size: 10px;
        line-height: 1.8em;
        vertical-align: middle;
        margin-right: 15px;
        &.blue {
          background-color: #3f8cff;
        }
        &.black {
          background-color: #000000;
        }
      }
      .numbercycles {
        font-size: 14px;
        font-family: 'PoppinsMedium';
        color: #11141a;
        font-weight: 600;
      }
    }
  }
}
.card-footer {
  .card-footer-item {
    flex-basis: unset;
    flex-shrink: unset;
  }
}
