.cyclesData {
  .column {
    .card {
      &.card-custom {
        box-shadow: 0px 5px 30px #20202312 !important;
        border-radius: 10px;
        .showing-text {
          margin-bottom: 20px;
          padding-left: 35px;
          color: #11141a;
          font-size: 12px;
        }
      }
      .card-header {
        .card-header-icon {
          .searchInput {
            position: absolute;
            width: 30%;
            right: 66px;
            .cancelbutton {
              position: absolute;
              top: 10px;
              width: 20px;
              right: -30px;
            }
          }
          .search {
            width: 20px;
          }
        }
      }
      .card-content {
        padding: 15px 35px 10px 35px;
      }
      .card-footer {
        border-top: none;
        .card-footer-item {
          &:not(:last-child) {
            border: none;
          }
          &.right {
            justify-content: flex-end;
            margin-right: 25px;
            .buttons {
              font-family: 'PoppinsMedium';
              font-size: 14px;

              &.prev-next {
                button {
                  &:not(:last-child):not(.is-fullwidth) {
                    margin-right: 15px;
                  }
                  .next {
                    margin-left: 15px;
                    width: 12px;
                    height: 12px;
                  }
                  .prev {
                    margin-right: 15px;
                    width: 12px;
                    height: 12px;
                  }
                  img {
                    width: 12px;
                    height: 12px;
                    &.prev {
                      margin-right: 15px;
                    }
                  }
                }
              }
              button {
                height: 44px;
                &.is-black {
                  background-color: #11141a;
                }
              }
            }
          }
          &.left {
            justify-content: start;
            margin-left: 23px;
            .text-of {
              color: #a6a9b7;
              font-size: 14px;
            }
          }
          .input {
            width: 48px;
            height: 34px;
            margin-right: 13px;
            text-align: center;
          }
        }
      }
    }
  }
  .customIcon {
    .button {
      width: 18px;
      height: 18px;
    }
  }
}
