.calendar-date-picker {
    float: left;
    max-width: 100%;
    margin-left: 18px;

    span {
        &.icon {
            top: 2px !important;
        }
    }

    .is-normal {
        flex-basis: auto;
        padding-top: 10px;

        .label {
            font-weight: normal;
            font-size: 12px;
        }
    }

    .rmdp-input {
        border: 1px solid #dbdbdb;
        border-radius: 0.5rem;
        height: 30px;
        font-size: 12px;
        width: 130px;
        padding: 5px 30px 5px 8px;
        font-family: "PoppinsMedium"!important;
    }
    
}
