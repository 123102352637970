.releaseForm {
  text-align: center;
  .column {
    .info {
      cursor: pointer;
      border: 1px solid #3f8cff;
      padding: 30px;
      h3 {
        font-size: 20px;
        color: black;
      }
      h4 {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
