.detailPressCycles {
  margin-top: 40px !important;
  margin-bottom: 20px !important;
  .column {
    .card {
      &.card-custom {
        box-shadow: 0px 5px 30px #20202312 !important;
        border-radius: 10px;
        .showing-text {
          margin-bottom: 20px;
          padding-left: 35px;
          color: #11141a;
          font-size: 12px;
        }
      }
      .card-header {
        .card-header-icon {
          .view-detail {
            font-size: 12px;
            height: 37px;
            border: 1px solid #11141a;
            border-radius: 8px;
            color: #11141a;
            font-family: 'PoppinsMedium';
            padding: 3px 17px 0 17px;
          }
        }
      }

      .card-content {
        padding: 15px 15px 10px 15px;
      }
      .card-footer {
        border-top: none;
        padding: 0;
        margin-bottom: 10px;
        .card-footer-item {
          justify-content: left;
          padding: 0;
          &.left {
            justify-content: start;
            margin-left: 33px;
          }
          &.right {
            justify-content: flex-end;
            margin-right: 25px;
          }
          &:not(:last-child) {
            border: none;
          }
          .input {
            width: 48px;
            height: 34px;
            margin-right: 13px;
            text-align: center;
          }
          .text-of {
            color: #a6a9b7;
            font-size: 14px;
          }
        }
      }
    }
  }
  .customIcon {
    .button {
      width: 18px;
      height: 18px;
    }
  }
  .rightButton {
    display: initial !important;
    text-align: right;
  }
}
