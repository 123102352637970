.homepage {
  #button-info {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 30px #2020231a;
    color: #11141a;
    font-family: 'PoppinsLight';
    font-size: 14px;
    height: 91px;
    top: 99px !important;
    margin-left: 20px;
    &.place-right {
      &::before {
        // border-top: 6px solid #fff;
        // border-bottom: 6px solid #fff;
        left: -8px;
        top: 20%;
        margin-top: -5px;
      }
      &::after {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        left: -6px;
        top: 20%;
        margin-top: -4px;
        border-right-color: #fff;
        border-right-style: solid;
        border-right-width: 6px;
      }
    }
    .cycle {
      h5 {
        &.mb-17 {
          margin-bottom: 17px;
          margin-top: 7px;
        }
        &.mb {
          margin-bottom: 17px;
        }
      }
    }
  }

  .DashboardHeader {
    h5 {
      color: #11141a;
      font-size: 15px;
      span {
        .infoImg {
          width: 13px;
          vertical-align: middle;
          margin-left: 7.5px;
          margin-right: 7.5px;
          cursor: pointer;
        }
        img {
          &.dotImg {
            width: 4px;
            vertical-align: middle;
            margin-left: 7.5px;
            margin-right: 7.5px;
          }
        }
      }
    }
  }
  .mb-5 {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 0px;
  }
  h4 {
    font-size: 45px;
    color: #11141a;
    letter-spacing: -0.9px;
    font-weight: 600;
    .vertical {
      .wip {
        background-color: #0066cc;
        font-size: 14px;
        border-color: transparent;
        color: #fff;
        border: 1px solid transparent;
        border-radius: 8px;
        box-shadow: none;
        height: 2.5em;
        padding: 7px 20px 0 20px;
        font-family: 'PoppinsLight';
      }
    }
    .bellbutton {
      .bell {
        width: 20px;
        height: 22px;
        vertical-align: middle;
        float: right;
        display: inline-grid;
        margin-top: 15px;
      }
    }
  }
}
