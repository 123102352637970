.tableUsers {
  margin-right: 50px !important;
  .showing-text {
    margin-bottom: 20px;
    color: #11141a;
    font-size: 12px;
  }
  .column {
    .card-footer {
      border-top: none;
      padding: 0;
      margin-bottom: 10px;
      .card-footer-item {
        justify-content: left;
        padding: 0;
        &.left {
          justify-content: start;
        }
        &.right {
          justify-content: flex-end;
        }
        &:not(:last-child) {
          border: none;
        }
        .input {
          width: 48px;
          height: 34px;
          margin-right: 13px;
          text-align: center;
        }
        .text-of {
          color: #a6a9b7;
          font-size: 14px;
        }
      }
    }
  }
  .vertical {
    display: inline-grid;
    vertical-align: middle;
    margin-left: 20px;
  }
  .card-header {
    box-shadow: none;
    padding: 20px 20px 0px 20px;
  }
  p.subtitle {
    font-size: 0.9rem;
  }
  p.title {
    font-size: 1.3rem;
  }
}
