.viewer {
  .vertical {
    display: inline-grid;
    vertical-align: middle;
  }
  .card-header {
    box-shadow: none;
  }
  p.subtitle {
    font-size: 0.9rem;
  }
  p.title {
    font-size: 1.3rem;
  }
}
