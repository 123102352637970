.usersTable {
  .table {
    width: 100%;
    thead {
      tr {
        &.custom-tr {
          font-size: 12px;
          height: 0;
          border-bottom: solid #e8e8e8;
          border-width: 0.1px;
          height: 50px;
          th {
            color: #a6a9b7;
            font-size: 10px;
            &:first-child {
              width: 10px;
            }
            &:last-child {
              padding: 0;
              text-align: right;
            }
          }
        }
        th {
          border: none;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
    tbody {
      tr {
        &.detail-cycle {
          border-bottom: solid #e8e8e8;
          border-width: 0.1px;
          th {
            &:first-child {
              padding: 0;
            }
            &:last-child {
              padding: 0;
              text-align: right;
            }
            button {
              &.processing {
                height: 15px;
                border-radius: 3px;
                font-size: 7px;
                width: 53px;
                background: #ffce73 0% 0% no-repeat padding-box;
                color: #11141a;
              }
            }
            .tooltip {
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 5px 30px #2020231a;
              color: #11141a;
              font-family: 'PoppinsLight';
              &.place-left {
                &::before {
                  border-top: 6px solid #fff;
                  border-bottom: 6px solid #fff;
                  left: -8px;
                  top: 20%;
                  margin-top: -5px;
                }
                &::after {
                  border-top: 5px solid transparent;
                  border-bottom: 5px solid transparent;
                  top: 20%;
                  margin-top: -4px;
                  border-left-color: #fff;
                  border-left-style: solid;
                  border-left-width: 6px;
                }
              }
            }
          }

          &:last-child {
            border-width: 0;
          }
          th {
            font-family: 'PoppinsLight';
            .dotImg {
              width: 12px;
              height: 12px;
              border-radius: 100%;
              vertical-align: middle;
              margin-right: 7.5px;
            }
            img {
              &.dotImg {
                width: 6px;
                height: 6px;
                background-color: #47d5cb;
                border-radius: 100%;
                vertical-align: middle;
                margin-right: 7.5px;
              }
              &.treeDotImg {
                width: 16px;
                height: 16px;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    tbody {
      &.cursorpointer {
        cursor: pointer;
        tr {
          th {
            &.radius-left-bottom {
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }
            &.dark-text {
              font-family: 'PoppinsMedium';
            }
            font-family: 'PoppinsLight';
          }
        }
      }
      &.card {
        background-color: #f7f8fa;
        border-radius: 8px;
        box-shadow: none !important;
        border: 0;
        &.active {
          box-shadow: 0 0.1em 0em -0.125em #5a46c7, 0 0px 0 0.1em #5a46c7;
        }
      }
      tr {
        height: 80px;
        .btnrelease {
          text-align: right;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          button {
            font-size: 12px;
            height: 36px;
            width: 78px;
            background-color: #0066cc;
          }
        }
        th {
          border: none;
          vertical-align: middle;
          font-size: 14px;
          color: #11141a;
          padding: 0.25em 0.5em;
          text-align: left;
          &.dark-text {
            color: #11141a;
            font-size: 16px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          .bordercolums {
            background-color: #fff;
            border: 0.5px solid #e8e8e8;
            border-radius: 6px;
            height: 96px;
            .p-3 {
              padding: 15px !important;
            }
            .column {
              border-right: solid #e8e8e8;
              border-width: 0.1px;
              .has-text-centered {
                text-align: center;
                color: #11141a;
                font-size: 25px;
              }
              &:last-child {
                border-right: none;
              }
              .numberbullet {
                border: 1px solid;
                display: inline-block;
                width: 20px;
                height: 20px;
                text-align: -webkit-center;
                border-radius: 100%;
                background-color: #3f8cff;
                color: #fff;
                font-size: 10px;
                line-height: 1.8em;
              }
            }
          }
          .box {
            box-shadow: 0.5px solid var(--unnamed-color-e8e8e8);
            padding-top: 15px;
            margin-bottom: 20px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 0;
          }
        }
      }
    }
    .m-5 {
      margin: 20px;
    }
  }
}
