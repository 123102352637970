.toolbox-report {
    float: left;
    max-width: 100%;
    margin-left: 18px;

    span {
        &.icon {
            top: 2px !important;
        }
    }

    .is-normal {
        flex-basis: auto;
        padding-top: 10px;

        .label {
            font-weight: normal;
            font-size: 12px;
        }
    }
    
    .field-label {
        margin-right: 1rem;
    }
    .toolbox {
        display: flex;
        
    }

    .css-pv2c8j {
        border: 1px solid #dbdbdb!important;
        border-radius: 0.5rem!important;
        height: 30px!important;
        font-size: 12px!important;
        font-family: "PoppinsMedium"!important;
    }

    .css-10erqo2 {
        border: 1px solid #dbdbdb!important;
        border-radius: 0.5rem!important;
        height: 30px!important;
        font-size: 12px!important;
        font-family: "PoppinsMedium"!important;
    }

    .css-1c2ka0i {
        border: 1px solid #dbdbdb!important;
        border-radius: 0.5rem!important;
        height: 30px!important;
        font-size: 12px!important;
        font-family: "PoppinsMedium"!important;
    }

    .css-1jllj6i-control {
        min-width: 0!important;
        width: 150px;
        margin: 0px 0px 0px 0px;
        font-size: 12px!important;
        font-family: "PoppinsMedium"!important;
    }

    .css-6y1x9t-control {
        min-width: 0!important;
        width: 150px;
        min-height: 0!important;
        height: 30px!important;
        margin: 0px 0px 8px 8px;
        font-size: 12px!important;
        font-family: "PoppinsMedium"!important;

    }
    .css-1pcexqc-container {
        font-size: 12px!important;
        width: 160px;
        font-family: "PoppinsMedium"!important;
    }

    .css-11unzgr{
        overflow: hidden;
        margin: 0px 0px 8px 8px;
    }
}
