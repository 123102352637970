#viewer-template {
  zoom: 80%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 28px;
  // .main {
  //   margin-left: 287px;
  // }
}
