.customTitle {
  font-size: 20px !important;
}
.modalClose {
  position: absolute;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
  top: 40px;
  right: 20px;
  background: transparent;
  border: 0;
}
.customContainer {
  h3 {
    margin-bottom: 16px;
  }
}
.rightButton {
  display: initial !important;
  text-align: right;
}
.pra {
  margin-bottom: 20px;
  font-size: 20px;
}
