div.sanmina-footer {
    position: fixed;
    background-color: #fff;
    font-size: 10px;
    line-height: 11px;
    z-index: 35;
    bottom: 0;
    height: 23px;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 6px 22px;

    .column.align-left {
        text-align: left;
    }

    .column.align-center {
        text-align: center;
    }

    .column.align-right {
        text-align: right;
        padding-right: 50px;
    }

    .column {
        img {
            height: 15px;
            position: absolute;
            margin-left: 3px;
            margin-top: -2px;
        }
    }
}

#viewer-template div.sanmina-footer {
    font-size: 14px;
    height: 30px;
    padding: 10px 0px 10px 40px;
    margin-left: -2%;

    .column {
        img {
            height: 21px;
            position: absolute;
            margin-left: 3px;
            margin-top: -5px;
        }
    }
}