.customTitle {
  font-size: 20px !important;
}
.modalCardMove {
  // width: 40vw;
}
.editSelect {
  margin-top: 20px;
  margin-bottom: 20px;
}
.modalClose {
  position: absolute;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
  top: 40px;
  right: 20px;
  background: transparent;
  border: 0;
}
.customContainer {
  h3 {
    margin-bottom: 16px;
  }
}
.rightButton {
  display: initial !important;
  text-align: right;
}
.editcyclebook{
  .h3-content{
    font-family: 'PoppinsLight';
  }
}
