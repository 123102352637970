.cyclesProgress {
    .column {
        .card {
            &.card-custom {
                box-shadow: 0px 5px 30px #20202312 !important;
                border-radius: 10px !important;
                padding-bottom: 20px;

                .showing-text {
                    margin-bottom: 20px;
                    padding-left: 35px;
                    color: #11141a;
                    font-size: 12px;
                }
            }

            .card-header {
                .card-header-icon {
                    .view-detail {
                        font-size: 12px;
                        height: 37px;
                        border: 1px solid #11141a;
                        border-radius: 8px;
                        color: #11141a;
                        font-family: 'PoppinsMedium';
                        padding: 3px 17px 0 17px;
                    }
                }
            }

            .customCard {
                padding: 15px 35px 10px 35px;
            }

            .card-footer {
                border-top: none;
                padding: 0;
                margin-bottom: 10px;

                .card-footer-item {
                    justify-content: left;
                    padding: 0;

                    &:not(:last-child) {
                        border: none;
                    }
                }
            }
        }
    }

    .customIcon {
        .button {
            width: 18px;
            height: 18px;
        }
    }
}
