.ViewerHeader {
    margin-bottom: 26px;

    h4 {
        font-size: 30px;
        text-align: center;
        padding-left: 258px;

        .vertical {
            margin-left: 20px;

            .button {
                height: 34px;
                width: 82px;
                font-size: 12px;
            }

            .logo-sanmina {
                text-align: right;

                img {
                    height: 50px;
                    margin: 0px;
                }
            }
        }
    }

    .sub-label {
        font-size: 16px;
        text-align: center;
        padding-left: 258px;
    }

    h5 {
        color: #11141a;
        font-size: 15px;

        span {
            .infoImg {
                width: 13px;
                vertical-align: middle;
                margin-left: 7.5px;
                margin-right: 7.5px;
                cursor: pointer;
            }

            img {
                &.dotImg {
                    width: 5px;
                    vertical-align: middle;
                    margin-left: 7.5px;
                    margin-right: 7.5px;
                }
            }
        }
    }

    .logo-container {
        width: auto;
        height: 0px;
        text-align: left;
        margin-top: -10px;

        img.logo {
            display: inline-block;
            width: 175px;
            margin-top: -80px;
        }
    }
}
