.wipSelection {
  header {
    &.card-header {
      .card-header-title {
        padding: 0 5px 10px;
      }
    }
  }
  .card-content {
    .content {
      .has-addons {
        .control {
          &.input-field {
            width: 330px;
          }
          .button {
            background-color: rgb(0, 102, 204);
          }
        }
      }
      .machine-master-list {
        margin-top: 40px;
      }
    }
  }
}
