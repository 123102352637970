.LoginForm {
  padding-left: 100px;
  padding-right: 100px;
  .input {
    background-color: #3c48a6 !important;
    border: 0;
    color: #000;
  }
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #3c48a6 inset;
  }
}
