.modalHeader {
  background-color: black;
  padding: 0.75rem;
  border-bottom: none;

  p {
    color: white;
  }
}

.modalBody {
  padding: 0;

  &.useMacFix {
    background-color: #3b4044;
  }
}

.modalContent {
  width: 50vw;

  @media screen and (max-width: 768px) {
    width: 95vw;
  }

  @media (min-width: 768px) and (max-width: 1080px) and (orientation: landscape) {
    width: 90vw;
  }
}
