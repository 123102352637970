.aside {
  position: fixed;
  width: 191px;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  float: left;
  margin-right: 60px;
  margin-left: 36px;
  border-right: 1px solid var(--unnamed-color-e8e8e8);
  border-right: 1px solid #e8e8e8;
  .media-content {
    &.mouse-cursor {
      cursor: pointer;
    }
  }
  .mt-5 {
    margin-top: 38px;
    margin-bottom: 79.86px;
    .logo {
      width: 175px;
    }
  }

  &.is-hover {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  &.is-active.is-hover {
    width: 15vw;
  }

  &.is-active:not(.is-hover) {
    flex: 0.2;
  }

  .drawer-button {
    background-color: transparent;
    border: none;

    .icon {
      color: white;
    }
  }
  .editProfile {
    padding: 20px;
    position: absolute;
    bottom: 40px;
    padding-left: 0;
    .media {
      .media-left {
        margin-right: 10px;
      }
    }
    .imageprofile {
      border-radius: 100%;
      width: 32px;
    }
    .title {
      font-size: 14px;
      color: #11141a;
    }
    .subtitle {
      font-size: 12px;
      color: #a6a9b7;
    }
    .dropdown-menu {
      margin-left: 30px;
    }
  }
  .poweredby {
    padding: 20px;
    position: absolute;
    bottom: 0;
    padding-left: 0;
    span {
      font-size: 14px;
      img {
        &.logo {
          width: 80%;
          vertical-align: middle;
        }
      }
    }
  }
}
