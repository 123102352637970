.TabsButtons {
  ul {
    border-bottom-width: 0 !important;
    li {
      margin-right: 20px;
      .button {
        font-size: 14px;
        font-family: 'PoppinsMedium';
        min-width: 90px;
        height: 44px;
        .is-white {
          color: #11141a;
        }
        &.is-active {
          background-color: #0066cc;
        }
      }
    }
  }
}
