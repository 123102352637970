.PressCycleChart {
    margin-bottom: 26px;

    h4 {
        clear: both;
        font-size: 16px;
        text-align: center;

        .vertical {
            margin-left: 20px;

            .button {
                height: 34px;
                width: 82px;
                font-size: 12px;
            }

            .logo-sanmina {
                text-align: right;

                img {
                    height: 50px;
                    margin: 0px;
                }
            }
        }
    }

    .sub-label {
        font-size: 16px;
        text-align: center;
        padding-left: 258px;
    }

    h5 {
        color: #11141a;
        font-size: 15px;

        span {
            .infoImg {
                width: 13px;
                vertical-align: middle;
                margin-left: 7.5px;
                margin-right: 7.5px;
                cursor: pointer;
            }

            img {
                &.dotImg {
                    width: 5px;
                    vertical-align: middle;
                    margin-left: 7.5px;
                    margin-right: 7.5px;
                }
            }
        }
    }

    .logo-container {
        width: auto;
        height: 0px;
        text-align: left;
        margin-top: -10px;

        img.logo {
            display: inline-block;
            width: 175px;
            margin-top: -80px;
        }
    }

    .chart {
        clear: both;
        .loading {
            position: absolute;
            z-index: 9;
            width: 100%;
            top: 50%;
            left: 50%;
            margin-top: 10px;
            transform: translate(-50%, -50%);
            height: 600px;
        }
        tspan {
            font-size: 11px;
            font-family: "PoppinsMedium"!important;
        }
    }

    .card {
        border: 1px solid #e8e8e8;
        border-radius: 10px!important;
        &.toolbox {
            height: 55px;
            padding: 10px 0px 0px 0px;
            margin-bottom: 30px;
            box-shadow: 0 5px 30px rgba(32,32,35,.07058823529411765)!important;
        }
       
    }
}
