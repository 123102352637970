.dropdown-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* .center-text-t {
      margin-bottom: 10px;
    } */
  @media screen and (max-width: 768px) and (orientation: portrait) {
    top: 35%;
    .message {
      font-size: 10px !important;
    }
  }

  @media screen and (max-width: 768px) and (orientation: landscape) {
    top: 40%;
  }

  .is-flex {
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  span {
    color: #414142;
    line-height: 11px;
  }

  .control.mt-2 {
    max-width: 40vw;

    @media screen and (max-width: 768px) and (orientation: portrait) {
      max-width: 90vw;
    }
  }

  .message {
    font-family: GilroyLight;
    background-color: transparent;
    font-size: 0.75rem;
    color: #74685a;
  }

  .dropdown {
    background-color: #f5f1e0;
    margin-left: 1rem;
    border-radius: 1rem;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      max-width: 90vw;
    }

    &.is-active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .dropdown-menu {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        display: grid;
      }
    }

    .dropdown-trigger {
      border-radius: inherit;
      /* .button {
          width: 200px;
        } */
    }

    .button,
    .dropdown-menu,
    .dropdown-content {
      background-color: inherit;
      font-family: GilroyRegular;
      font-size: small;
      border-radius: inherit;
      white-space: unset;
      width: 100%;

      .dropdown-item {
        padding-right: 10px;
      }

      @media screen and (max-width: 768px) and (orientation: portrait) {
        width: 90vw;
      }

      @media screen and (max-width: 768px) and (orientation: landscape) {
        width: 57vw;
      }
    }

    .dropdown-content {
      max-height: 13rem;
      overflow: auto;
    }

    button.dropdown-item {
      font-family: GilroyRegular;
      font-size: small;
      border: none;
      background-color: transparent;
      cursor: pointer;
      white-space: unset;

      &.is-active {
        background-color: #3273dc;
        color: white;
      }
    }
  }
}
