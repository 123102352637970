.column {
  &.activeCycle {
    .card {
      .cardCycleProgress {
        background-color: #0066cc;
        .title {
          color: #ffffff;
          font-size: 11px;
        }
        .subtitle {
          color: #ffffff;
          font-size: 9px;
          margin-bottom: 6px;
        }
      }
    }
  }
}

.cardCycleProgress {
  .isVisit {
    background-color: #0066cc;
    cursor: pointer;
  }
  &:hover {
    background-color: #0066cc;
    cursor: pointer;
    .title {
      color: #ffffff;
      font-size: 11px;
    }
    .subtitle {
      color: #ffffff;
      font-size: 9px;
      margin-bottom: 6px;
    }
  }
  padding: 8px !important;
  .media {
    .media-left {
      margin-right: 13px;
      figure {
        width: 55px;
        height: 55px;
        padding: 10px;
        background: #ff754c;
        border-radius: 10px;
      }
    }
    .media-content {
      line-height: 0;
      .title {
        font-size: 11px;
        line-height: 17px;
      }
      .subtitle {
        font-size: 9px;
        margin-bottom: 6px;
        span {
          img {
            &.dotImg {
              width: 2px;
              vertical-align: middle;
              margin-left: 5px;
              margin-right: 5px;
            }
          }
        }
      }
      button {
        border-radius: 3px;
        height: 15px;
        font-size: 7px;
        line-height: 1;
        font-family: 'PoppinsMedium';
        &.processing {
          background-color: #ffce73;
          text-transform: capitalize;
        }
      }
    }
  }
}
