.viewerPressOptimization {
  .column {
    .card-custom {
      background-color: #f7f8fa;
      header {
        .release-right {
          font-size: 12px;
          span {
            span {
              img {
                &.dotImg {
                  width: 5px;
                  height: 5px;
                  vertical-align: middle;
                  margin-right: 7.5px;
                  margin-left: 7px;
                }
              }
            }
          }
        }
        .card-header-title {
          padding: 10px 10px;
          .sub-title {
            margin-left: 15px;
          }
          button {
            width: 92px;
            height: 28px;
            font-size: 12px;
            background-color: #11141a;
            border-radius: 6px;
            &.is-transparant {
              background-color: transparent;
              border-color: transparent;
              color: transparent;
            }
          }
          .dotImg {
            &.recipe {
              width: 5px;
              height: 5px;
              vertical-align: middle;
              margin-right: 7.5px;
              margin-left: 7px;
            }
          }
        }
      }
      .card-content {
        padding-top: 10px;
        padding-bottom: 0;
        padding-left: 18px;
        padding-right: 18px;
      }
    }
    &.mb-2 {
      margin-bottom: 10px;
    }
    &.pt-0 {
      padding-top: 0;
    }
    &.p-1rem {
      padding-bottom: 1.7rem;
    }
    .float-right {
      float: right;
      margin-top: -13px;
      margin-right: 20px;
      border-radius: 0;
      border-top-width: 0;
    }
    .btn-collapse {
      background-color: #3273dc;
      color: white;
    }
  }
}
