.filterdropdown {
  max-width: 100%;
  span {
    &.icon {
      top: 2px !important;
    }
  }
  .is-normal {
    flex-basis: auto;
    padding-top: 10px;
    .label {
      font-weight: normal;
    }
  }
  .select {
    &.is-fullwidth {
      width: 200px;
    }
  }
}
