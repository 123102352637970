.sidebar-item {
  a {
    img {
      width: 31px;
      padding: 10px;
      vertical-align: middle;
      border-radius: 7px;
      margin-right: 10px;
    }
  }
  &.is-active {
    a {
      img {
        width: 31px;
        padding: 10px;
        background-color: #0066cc;
        vertical-align: middle;
        border-radius: 7px;
        margin-right: 10px;
      }
    }
  }
  padding-left: 0 !important;
  &:not(.is-icon) {
    padding: 1rem;
  }

  transition: 0.3s;

  &.is-active,
  &:hover {
    background-color: #ffffff;
    a {
      color: #11141a;
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 100%;

    &.is-icon {
      padding: 0 1em;
    }
  }

  a,
  button {
    color: #a6a9b7;
    transition: 0.3s;
    display: block;
    font-size: 14px;
    text-align: left;
  }
}
