.table {
  width: 100%;
  .table {
    .custom-tr {
      font-size: 12px;
      height: 0;
      border-bottom: solid #e8e8e8;
      border-width: 0.1px;
      height: 50px;
      th {
        color: #a6a9b7;
        font-size: 10px;
      }
    }
    .detail-cycle {
      border-bottom: solid #e8e8e8;
      border-width: 0.1px;

      th {
        &:first-child {
          padding: 0;
        }
        &:last-child {
          padding: 0;
          text-align: right;
        }
      }

      &:last-child {
        border-width: 0;
      }
      th {
        font-family: 'PoppinsLight';
        img {
          &.dotImg {
            width: 12px;
            height: 12px;
            vertical-align: middle;
            margin-right: 7.5px;
          }
          &.treeDotImg {
            width: 6px;
            height: 15px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  thead {
    tr {
      th {
        border: none;
        font-weight: normal;
        font-size: 12px;
        padding-left: 22px;
        padding-bottom: 20px;
      }
    }
  }
  tbody {
    &.cursorpointer {
      cursor: pointer;
      tr {
        th {
          &.radius-left-bottom {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          &.dark-text {
            font-family: 'PoppinsMedium';
          }
          font-family: 'PoppinsLight';
        }
      }
    }
    &.card {
      background-color: #f7f8fa;
      border-radius: 8px;
      box-shadow: none !important;
      border: 0;
      &.activeCard {
        box-shadow: 0 0.1em 0em -0.125em #0066cc, 0 0px 0 0.1em #0066cc !important;
      }
    }
    tr {
      height: 80px;
      .btnrelease {
        text-align: right;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        aside {
          position: absolute;
          background: white;
          text-align: left;
          box-shadow: 0px 5px 30px #2020231a;
          width: 133px;
          border-radius: 4px;
          right: 90px;
          margin-top: -40px;
          ul {
            li {
              padding: 10px;
              a {
                font-size: 14px;
                color: #11141a;
                &:hover {
                  background-color: transparent;
                  color: #3f8cff;
                }
              }
            }
          }
        }
        button {
          font-size: 12px;
          height: 36px;
          width: 78px;
          background-color: #0066cc;
          vertical-align: middle;
          &:hover {
            background-color: #ccff33;
            color: #000;
          }
        }
        img {
          &.treeDotImg {
            width: 16px;
            height: 16px;
            vertical-align: middle;
            margin-left: 10px;
          }
        }
      }
      th {
        border: none;
        vertical-align: middle;
        font-size: 14px;
        color: #11141a;
        padding-left: 30px;
        padding-right: 30px;
        &.dark-text {
          color: #11141a;
          font-size: 16px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        .bordercolums {
          background-color: #fff;
          border: 0.5px solid #e8e8e8;
          border-radius: 6px;
          height: 96px;
          .p-3 {
            padding: 15px !important;
          }
          .column {
            border-right: solid #e8e8e8;
            border-width: 0.1px;
            .has-text-centered {
              text-align: center;
              color: #11141a;
              font-size: 25px;
            }
            &:last-child {
              border-right: none;
            }
            .numberbullet {
              border: 1px solid;
              display: inline-block;
              width: 20px;
              height: 20px;
              text-align: -webkit-center;
              border-radius: 100%;
              color: #fff;
              font-size: 10px;
              line-height: 1.8em;
              &.blue {
                background-color: #3f8cff;
              }
              &.black {
                background-color: #000000;
              }
            }
          }
        }
        .box {
          box-shadow: 0.5px solid var(--unnamed-color-e8e8e8);
          padding-top: 15px;
          margin-bottom: 20px;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 0;
        }
      }
    }
  }
  .m-5 {
    margin: 20px;
  }
}
