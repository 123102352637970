.viewerPressOptimization {
    .column {
        .card-custom {
            background-color: #f7f8fa;

            header {
                .release-right {
                    font-size: 12px;
                }

                .card-header-title {
                    padding: 10px 10px;

                    .sub-title {
                        margin-left: 15px;
                    }

                    button {
                        width: 92px;
                        height: 28px;
                        font-size: 12px;
                        background-color: #11141a;
                        border-radius: 6px;
                    }
                }
            }

            .card-content {
                padding-top: 10px;
                padding-bottom: 0;
                padding-left: 18px;
                padding-right: 18px;
            }
        }
    }
}

.viewerMachine {
    margin-bottom: 50px;
    clear: both;

    .loadingViewerData {
        position: absolute;
        z-index: 9;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .loading {
            margin: 0 auto;
        }
    }
}
