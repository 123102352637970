.history {
  margin-right: 50px !important;
  .column {
    .rightInfo {
      margin-bottom: 20px;
      span {
        font-size: 14px;
      }
      img {
        &.dotImg {
          width: 5px;
          height: 5px;
          vertical-align: middle;
          margin-right: 7.5px;
          margin-left: 7px;
        }
      }
    }
  }
  .vertical {
    display: inline-grid;
    vertical-align: middle;
    margin-left: 20px;
  }
  .card-header {
    box-shadow: none;
    padding: 20px 20px 0px 20px;
  }
  p.subtitle {
    font-size: 0.9rem;
  }
  p.title {
    font-size: 1.3rem;
  }
}
.history {
  .card-footer {
    border-top: none;
    padding: 0;
    margin-bottom: 10px;
    .card-footer-item {
      &.left {
        justify-content: start;
        padding-left: 0;
      }
      .input {
        width: 48px;
        height: 34px;
        margin-right: 13px;
        text-align: center;
      }
      &.right {
        justify-content: flex-end;
        padding-right: 0;
      }
    }
  }
}
